




import { EntryListContent } from '@/api/interfaces/content/common/entryListContent';
import Icon from '@/components/atoms/icon/Icon.vue';
import { camelCase as _camelCase } from 'lodash';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'TechnologyIcon',
    components: {
        Icon,
    },
    props: {
        technology: {
            type: String,
            required: true,
        },
        technologyTitles: {
            type: Object as PropType<EntryListContent>,
            required: true,
        },
    },
    computed: {
        iconId(): string {
            switch (_camelCase(this.technology)) {
                case 'photovoltaicPowerPlant':
                    return 'Solaranlage';
                case 'windPowerPlant':
                    return 'Windenergieanlage';
                case 'hydroPowerPlant':
                    return 'Wasserkraftanlage';
                case 'steamTurbine':
                    return 'Wasserdampfturbine';
                case 'storage':
                    return 'Speicher';
                case 'thermalPowerPlantDea':
                    return 'BlockheizkraftwerkDea';
                case 'thermalPowerPlantKwk':
                    return 'BlockheizkraftwerkKwk';
                case 'orcPowerPlant':
                    return 'ORC-Anlage';
                case 'fuelCell':
                    return 'Brennstoffzelle';
                case 'gasTurbine':
                    return 'Gasturbine';
                case 'emergencyGenerator':
                    return 'Notstromaggregat';
                case 'stirlingEngine':
                    return 'Stirlingmotor';
                case 'bio':
                    return 'Bio';
                default:
                    return 'Erzeugung-filled';
            }
        },
        title(): string {
            const title = this.technologyTitles[
                _camelCase(this.technology)
            ] as string;

            return title ?? this.technology;
        },
    },
});
