


























import Vue from 'vue';
import ApplicationOverviewGrid from '@/components/organisms/applicationOverviewGrid/ApplicationOverviewGrid.vue';
import PageTemplate from '@/components/templates/pageTemplate/PageTemplate.vue';

export default Vue.extend({
    name: 'ApplicationOverviewTemplate',
    components: {
        PageTemplate,
        ApplicationOverviewGrid,
    },
    props: {
        applications: {
            type: Array,
            required: true,
        },
        applicationOverviewContent: {
            type: Object,
            required: true,
        },
        enumsContent: {
            type: Object,
            required: true,
        },
    },
});
