
















































































































































































































import Vue, { PropType } from 'vue';
import { applicationRepository, documentRepository } from '@/api';
import router from '@/router';
import AppAlerts from '@/components/molecules/appAlerts/AppAlerts.vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import ButtonSpacer from '@/components/molecules/buttonSpacer/ButtonSpacer.vue';
import AppButton from '@/components/atoms/appButton/AppButton.vue';
import AppContent from '@/components/atoms/appContent/AppContent.vue';
import AppLayer from '@/components/molecules/appLayer/AppLayer.vue';
import AppScrollbar from '@/components/atoms/appScrollbar/AppScrollbar.vue';
import TechnologyIcon from '@/components/atoms/technologyIcon/TechnologyIcon.vue';
import { ApplicationStatus } from '@/enums/applicationStatus';
import { MessageType } from '@/enums/messageType';
import { alertService } from '@/services/alertService';
import type { Application } from '@/api/interfaces/application/application';
import { SpinnerState } from '@/enums/spinnerState';
import { Address } from '@/api/interfaces/application/address';
import { camelCase } from 'lodash';
import { ApplicationOverviewGridContent } from '@/api/interfaces/content/app/applicationOverviewGridContent';
import { EntryListContent } from '@/api/interfaces/content/common/entryListContent';
import { ConfirmationLayerContent } from '@/api/interfaces/content/common/confirmationLayerContent';
import { ServiceError } from '@/api/interfaces/serviceError';
import { downloadFile } from '@/services/fileDownloadService';
import { isServiceError } from '@/api/interfaces/typeGuards';
import { EnergyTypeFieldType } from '@/types/forms/shared/energyTypeFieldType';
import { PlantTypeFieldTypeForGenerationTypeFormStep } from '@/types/forms/shared/plantTypeFieldType';

interface GridColumn {
    key: string;
}

const gridColumns: GridColumn[] = [
    { key: 'technology' },
    { key: 'applicationId' },
    { key: 'applicationNumber' },
    { key: 'created' },
    { key: 'submitted' },
    { key: 'completed' },
    { key: 'name' },
    { key: 'address' },
    { key: 'status' },
];

export default Vue.extend({
    name: 'ApplicationOverviewGrid',
    components: {
        AppAlerts,
        AppButton,
        AppContent,
        AppLayer,
        AppScrollbar,
        ButtonSpacer,
        Icon,
        SpinnerArea,
        TechnologyIcon,
    },
    props: {
        entries: {
            type: Array as PropType<Application[]>,
            default: () => [],
        },
        content: {
            type: Object as PropType<ApplicationOverviewGridContent>,
            required: true,
        },
        copyApplicationConfirmationLayerContent: {
            type: Object as PropType<ConfirmationLayerContent>,
            required: true,
        },
        deleteApplicationConfirmationLayerContent: {
            type: Object as PropType<ConfirmationLayerContent>,
            required: true,
        },
        enumsContent: {
            type: Object as PropType<EntryListContent>,
            required: true,
        },
    },
    data() {
        return {
            spinner: SpinnerState.off,
            alertService,
            alert: '',
            type: '',
            gridColumns: gridColumns,
            applicationIdToDelete: null as string | null,
            applicationIdToCopy: null as string | null,
        };
    },
    computed: {
        hasApplications(): boolean {
            return this.entries.length > 0;
        },
    },
    methods: {
        camelCase,
        renderApplicationAddress(address: Address): string {
            const postCodeAndCity = `${address.postCode ?? ''} ${
                address.city ?? ''
            }`.trim();
            const streetAndHousenumber = `${address.street ?? ''} ${
                address.houseNumber ?? ''
            }`.trim();
            const meadowAndLot = `${address.meadow ?? ''} ${
                address.lot ?? ''
            }`.trim();
            const subdistrictAndMeadowAndLot = `${
                address.subdistrict ?? ''
            } ${meadowAndLot}`.trim();
            return `${
                postCodeAndCity ? `${postCodeAndCity}<br />` : ''
            }${streetAndHousenumber} ${subdistrictAndMeadowAndLot}`.trim();
        },
        getTechnology(
            energyType: EnergyTypeFieldType,
            plantType: PlantTypeFieldTypeForGenerationTypeFormStep,
        ): string {
            switch (energyType) {
                case 'biogas':
                case 'biowaste':
                    return 'bio';
                default:
                    return plantType || '';
            }
        },
        startApplication(): void {
            router.push('/antrag');
        },
        canContinue(status: ApplicationStatus) {
            return status === ApplicationStatus.draft;
        },
        canDownload(status: ApplicationStatus) {
            return status !== ApplicationStatus.draft;
        },
        canDelete(status: ApplicationStatus) {
            return status !== ApplicationStatus.proposalSent;
        },
        canCopy(status: ApplicationStatus) {
            return status !== ApplicationStatus.draft;
        },
        prepareCopyingApplication(applicationId: string) {
            this.applicationIdToCopy = applicationId;
        },
        cancelCopyingApplication() {
            this.applicationIdToCopy = null;
        },
        copyApplication() {
            if (!this.applicationIdToCopy) {
                return;
            }

            const applicationId = this.applicationIdToCopy;
            this.applicationIdToCopy = null;
            this.spinner = SpinnerState.transparent;

            applicationRepository
                .copyApplication(applicationId)
                .then((response) => {
                    alertService.pushAlert({
                        alert: {
                            type: MessageType.info,
                            identifier: 'applications.copied',
                        },
                    });

                    this.$emit('copy-application', response);
                })
                .catch((error: ServiceError) => {
                    if (isServiceError(error)) {
                        alertService.pushAlert({
                            alert: {
                                type: MessageType.error,
                                identifier: error.messageCode,
                            },
                        });
                    }
                })
                .finally(() => {
                    this.spinner = SpinnerState.off;
                });
        },
        prepareDeletingApplication(applicationId: string) {
            this.applicationIdToDelete = applicationId;
        },
        cancelDeletingApplication() {
            this.applicationIdToDelete = null;
        },
        deleteApplication() {
            if (!this.applicationIdToDelete) {
                return;
            }

            const applicationId = this.applicationIdToDelete;
            this.applicationIdToDelete = null;
            this.spinner = SpinnerState.transparent;

            applicationRepository
                .deleteApplication(applicationId)
                .then(() => {
                    alertService.pushAlert({
                        alert: {
                            type: MessageType.info,
                            identifier: 'applications.deleted',
                        },
                    });

                    this.$emit('delete-application', applicationId);
                })
                .catch((error: ServiceError) => {
                    if (isServiceError(error)) {
                        alertService.pushAlert({
                            alert: {
                                type: MessageType.error,
                                identifier: error.messageCode,
                            },
                        });
                    }
                })
                .finally(() => {
                    this.spinner = SpinnerState.off;
                });
        },
        async downloadDocumentsZip(applicationId: string) {
            this.spinner = SpinnerState.transparent;

            documentRepository
                .downloadDocument('applicationDocumentsZip', applicationId)
                .then((response) => {
                    downloadFile(response.bytes, response.fileName);
                })
                .catch((error: ServiceError) => {
                    if (isServiceError(error)) {
                        alertService.pushAlert({
                            alert: {
                                type: MessageType.error,
                                identifier: error.messageCode,
                            },
                        });
                    }
                })
                .finally(() => {
                    this.spinner = SpinnerState.off;
                });
        },
    },
});
