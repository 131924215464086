var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SpinnerArea',{attrs:{"state":_vm.spinner}},[_c('ButtonSpacer',{attrs:{"button-text":_vm.content.newApplicationText},on:{"button-click":_vm.startApplication}}),_c('AppAlerts'),(!_vm.hasApplications)?_c('AppContent',{attrs:{"content":_vm.content.noApplicationsText}}):_c('AppScrollbar',{staticClass:"w-full"},[_c('table',{staticClass:"overview table-auto w-full"},[_c('thead',[_c('tr',[_vm._l((_vm.gridColumns),function(gridColumn){return _c('th',{key:gridColumn.key,staticClass:"text-primary align-top text-left py-4 pr-5 dea-rich-text",class:{
                            'hide-application-id':
                                gridColumn.key == 'applicationId',
                        },domProps:{"innerHTML":_vm._s(
                            !!_vm.content.columns[gridColumn.key]
                                ? _vm.content.columns[gridColumn.key].name
                                : ''
                        )}})}),_c('th')],2)]),_c('tbody',_vm._l((_vm.entries),function(entry){return _c('tr',{key:entry.applicationId,staticClass:"odd:bg-surface-variant"},[_vm._l((_vm.gridColumns),function(gridColumn){return _c('td',{key:gridColumn.key,class:{
                            'hide-application-id':
                                gridColumn.key == 'applicationId',
                        }},[(gridColumn.key === 'technology')?_c('TechnologyIcon',{staticClass:"text-3xl",attrs:{"technology":_vm.getTechnology(
                                    _vm.camelCase(entry.energyType),
                                    _vm.camelCase(entry.plantType)
                                ),"technology-titles":_vm.enumsContent.technology}}):(gridColumn.key == 'address')?_c('span',{staticClass:"dea-rich-text",domProps:{"innerHTML":_vm._s(
                                _vm.renderApplicationAddress(
                                    entry[gridColumn.key]
                                )
                            )}}):(gridColumn.key == 'status')?_c('span',{staticClass:"dea-rich-text",domProps:{"innerHTML":_vm._s(
                                _vm.enumsContent.applicationStatus[
                                    _vm.camelCase(entry[gridColumn.key])
                                ]
                            )}}):_c('span',{staticClass:"dea-rich-text",domProps:{"innerHTML":_vm._s(entry[gridColumn.key])}})],1)}),_c('td',{staticClass:"icon whitespace-no-wrap"},[(_vm.canContinue(entry.status))?_c('router-link',{staticClass:"text-secondary text-3xl hover:text-secondary-variant",attrs:{"to":{
                                path: ("/antrag/" + (entry.applicationId)),
                            }}},[_c('Icon',{attrs:{"id":"bleistift","title":_vm.content.editButtonText}})],1):_vm._e(),(_vm.canDownload(entry.status))?_c('button',{staticClass:"text-secondary text-3xl hover:text-secondary-variant",attrs:{"title":_vm.content.downloadButtonText},on:{"click":function($event){return _vm.downloadDocumentsZip(entry.applicationId)}}},[_c('Icon',{attrs:{"id":"Download-filled"}})],1):_vm._e(),(_vm.canCopy(entry.status))?_c('button',{staticClass:"text-secondary text-3xl hover:text-secondary-variant",attrs:{"title":_vm.content.copyButtonText},on:{"click":function($event){return _vm.prepareCopyingApplication(
                                    entry.applicationId
                                )}}},[_c('Icon',{attrs:{"id":"copy"}})],1):_vm._e(),(_vm.canDelete(entry.status))?_c('button',{staticClass:"text-secondary text-3xl hover:text-secondary-variant",attrs:{"title":_vm.content.deleteButtonText},on:{"click":function($event){return _vm.prepareDeletingApplication(
                                    entry.applicationId
                                )}}},[_c('Icon',{attrs:{"id":"Abfall-filled"}})],1):_vm._e()],1)],2)}),0)])]),_c('ButtonSpacer',{attrs:{"button-text":_vm.content.newApplicationText},on:{"button-click":_vm.startApplication}}),_c('AppLayer',{attrs:{"show":_vm.applicationIdToCopy !== null},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('AppContent',{attrs:{"use-large-font":true,"content":_vm.copyApplicationConfirmationLayerContent.confirmationText}})]},proxy:true},{key:"action1",fn:function(){return [_c('AppButton',{attrs:{"label":_vm.copyApplicationConfirmationLayerContent.buttonAccept
                        .text},on:{"button-click":function($event){return _vm.copyApplication()}}})]},proxy:true},{key:"action2",fn:function(){return [_c('AppButton',{attrs:{"label":_vm.copyApplicationConfirmationLayerContent.buttonCancel
                        .text,"character":"cross"},on:{"button-click":function($event){return _vm.cancelCopyingApplication()}}})]},proxy:true}])}),_c('AppLayer',{attrs:{"show":_vm.applicationIdToDelete !== null},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('AppContent',{attrs:{"use-large-font":true,"content":_vm.deleteApplicationConfirmationLayerContent.confirmationText}})]},proxy:true},{key:"action1",fn:function(){return [_c('AppButton',{attrs:{"label":_vm.deleteApplicationConfirmationLayerContent.buttonAccept
                        .text},on:{"button-click":function($event){return _vm.deleteApplication()}}})]},proxy:true},{key:"action2",fn:function(){return [_c('AppButton',{attrs:{"label":_vm.deleteApplicationConfirmationLayerContent.buttonCancel
                        .text,"character":"cross"},on:{"button-click":function($event){return _vm.cancelDeletingApplication()}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }