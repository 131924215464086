













import Vue from 'vue';
import { applicationRepository } from '@/api';
import ApplicationOverviewTemplate from '@/components/templates/applicationOverviewTemplate/ApplicationOverviewTemplate.vue';
import { Application } from '@/api/interfaces/application/application';
import { contentServices } from '@/services/contentService';
import type { ApplicationOverviewContent } from '@/api/interfaces/content/app/applicationOverviewContent';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { EntryListContent } from '@/api/interfaces/content/common/entryListContent';
import { ServiceError } from '@/api/interfaces/serviceError';
import { alertService } from '@/services/alertService';
import { MessageType } from '@/enums/messageType';
import { dialogueMessageService } from '@/services/dialogueMessageService';
import { isServiceError } from '@/api/interfaces/typeGuards';
import router from '@/router';

export default Vue.extend({
    name: 'ApplicationOverview',
    components: {
        ApplicationOverviewTemplate,
        SpinnerArea,
    },
    data: () => {
        return {
            spinner: SpinnerState.off,
            applicationOverviewContent: null as ApplicationOverviewContent | null,
            enumsContent: null as EntryListContent | null,
            applications: [] as Application[],
        };
    },
    created() {
        if (this.$route.query.code === 'unauthorized') {
            dialogueMessageService.show('unauthorized', MessageType.error);
        }
    },
    async mounted() {
        this.spinner = SpinnerState.opaque;

        const content = await contentServices.app.requestContentOnce();
        this.applicationOverviewContent =
            content.pages.applicationOverviewContent;
        this.enumsContent = content.enumsContent;

        try {
            this.applications = await applicationRepository.fetchApplications();
        } catch (error) {
            if (isServiceError(error)) {
                alertService.clear();
                alertService.pushAlert({
                    alert: {
                        type: MessageType.error,
                        identifier: (error as ServiceError).messageCode,
                    },
                    displayDuration: 0,
                });
            }
        }

        this.spinner = SpinnerState.off;
    },
    methods: {
        updateApplicationsAfterCopy(copiedApplicationId: string): void {
            router.push(`/antrag/${copiedApplicationId}`);
        },
        updateApplicationsAfterDelete(deletedApplicationId: string): void {
            this.applications = this.applications.filter(
                (application) =>
                    application.applicationId !== deletedApplicationId,
            );
        },
    },
});
