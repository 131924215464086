<template>
    <div class="overflow-auto scrollbar">
        <slot></slot>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'AppScrollbar',
});
</script>

<style lang="postcss" scoped>
.scrollbar {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        @apply bg-surface-variant-100 shadow-xs rounded;
    }
}
</style>
